<template>
    <div class="property-members-container flex-column">
        <div class="property-members-container__header flex-row ai-c jc-sb">
          <el-tooltip
            effect="light"
            :content="property?.address"
            placement="bottom"
          >
            <p class="property-members-container__header-title default-text">{{ property?.address }}</p>
          </el-tooltip>
            <DisplayViewComponent :viewType="viewType" @set-view="setView" :showAddButton="true" @add-function="setProjectManageMembersModal(true)"/>
        </div>
        <div class="no-content-container" v-if="!projectMembers.length">
           <NoContentComponent :data="noContent"/>
        </div>
        <div class="tile-view-members-container" v-if="project && viewType === 'tile' && projectMembers.length">
          <el-card v-for="(member, index) in projectMembers" :key="index">
            <div class="tile-view-members-container__row flex-row ai-fs jc-sb">
              <div class="tile-view-members-container__profile-details">
                  <UserThumbnail
                  borderRadius="50px"
                  width="75px"
                  height="75px"
                  :key="require(`@/assets/images/box-empty.jpeg`)"
                  :source="require(`@/assets/images/box-empty.jpeg`)"
                  >
                  </UserThumbnail>
                  <div class="member-name">
                      <p class="first-name">--</p>
                      <p class="last-name">--</p>
                  </div>
              </div>
              <div class="flex-row ai-c jc-c cursor-pointer" @click="onDeleteMember(member.id)">
                <inline-svg width="25" height="25" :src="require('@/assets/icons/delete-icon.svg')"/>
              </div>
            </div>
            <div class="member-email-container">
                <p class="label">Email</p>
                <p class="email">{{member.email}}</p>
            </div>
            <el-divider border-style="double" />
            <div class="member-role-container">
                <p class="label">Role</p>
                <el-select class="members-role" v-model="value[index]" filterable placeholder="Role" @change="updateMember(member.id, value[index])">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
                <!-- <p class="email">{{member.memberPermission.name.toUpperCase()}}</p> -->
            </div>
          </el-card>
        </div>
        <div class="property-members-container__list-view" v-if="viewType === 'list' && projectMembers.length > 0">
            <MembersListComponent :membersList="projectMembers" @show-delete-modal="onDeleteMember($event)" @update-member="updateMember($event.memberId, $event.permissionId)"/>
        </div>

        <!-- MODAL -->
        <ConfirmDeleteMemberModal :show="showConfirmDeleteModal" @on-confirm="deleteMember" @on-cancel="onCancel"/>
    </div>
</template>
<script>
import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import MembersListComponent from '@/core/components/common/list/MembersListComponent.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import DisplayViewComponent from '@/modules/project-search/components/DisplayViewComponent.vue';
import ConfirmDeleteMemberModal from '@/modules/property-details-children/components/modal/ConfirmDeleteMemberModal.vue';
import NoContentComponent from '@/modules/property-details-children/components/NoContentComponent.vue';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { PROJECTS_STORE } from '@/store/modules/projects';

export default {
  components: {
    UserThumbnail,
    NoContentComponent,
    DisplayViewComponent,
    MembersListComponent,
    ConfirmDeleteMemberModal
  },
  data() {
    return {
      value: [],
      options: [
        {
          value: 1,
          label: 'View',
        },
        {
          value: 2,
          label: 'Edit',
        },
      ],

      projectMembers: [],
      windowWidth: window.innerWidth,
      showConfirmDeleteModal: false,
      memberDeleteId: null,

      project: null
    };
  },

  setup() {
    const input = ref('');
    const viewType = ref('list');
    const noContent = ref(
      /* eslint-disable global-require */
      {
        icon: `${require('@/assets/icons/no-members-icon.svg')}`, message: 'No members'
      },
      /* eslint-disable global-require */
    );

    function setView(event) {
      viewType.value = event;
    }

    return {
      input,
      viewType,
      setView,
      noContent
    };
  },
  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
      this.checkWidth();
    },

    addedProjectMembers: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.onInitialize(value);
        }
      }
    },

    // projectMembers: {
    //   immediate: true,
    //   handler(value) {
    //     if (value) {
    //       this.onInitialize();
    //     }
    //   }
    // }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  async created() {
    const memberPermissions = await this.getMemberPermissions();
    this.checkWidth();
    this.onInitialize();
    this.options = memberPermissions.map((memberPermission) => {
      return {
        value: memberPermission.id,
        label: memberPermission.name.toUpperCase()
      };
    });
  },

  computed: {
    ...mapGetters(['isTradesperson']),

    ...mapGetters(PROJECTS_STORE, [
      'currentSelectedProject',
    ]),

    ...mapGetters(PROPERTIES_PROJECT_POST_STORE, [
      'addedProjectMembers',
    ]),
  },

  methods: {
    ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
      'setProjectMembers',
      'getProjectMembers',
      'createProjectMembers',
      'updateProjectMember',
      'deleteProjectMember',
      'setAddedProjectMembers',
      'getMemberPermissions',
      'setUnsavedProjectMembers'
    ]),

    ...mapActions(['setProjectManageMembersModal']),

    navigateToProjectOverview(projectRefId) {
      this.$router.push(`/projects/${projectRefId}/project-details/overview`);
    },

    async onInitialize() {
      if (!this.currentSelectedProject || this.isTradesperson) {
        const { params } = this.$route;
        const { projectRefId } = params;
        this.navigateToProjectOverview(projectRefId);
      }

      const { currentSelectedProject } = this;

      if (currentSelectedProject) {
        const { id: projectId } = currentSelectedProject;

        this.project = currentSelectedProject;

        await this.getProjectMembers(projectId).then((response) => {
          if (response) {
            this.projectMembers = response;

            if (response.length) {
              this.setProjectMembers(response);
            }
            for (let i = 0; i < this.projectMembers.length; i++) {
              this.value.push(this.projectMembers[i].memberPermissionId);
            }
          }
        });

        this.setAddedProjectMembers(false);
      }
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },
    checkWidth() {
      if (this.windowWidth < 768) {
        this.viewType = 'tile';
      }
    },
    updateMember(projectMemberId, permissionId) {
      const { currentSelectedProject } = this;
      const { id: projectId } = currentSelectedProject;

      if (projectMemberId && permissionId && projectId) {
        const payload = {
          projectId,
          projectMemberId,
          formData: { memberPermissionId: permissionId }
        };
        this.updateProjectMember(payload).then(async () => {
          this.setUnsavedProjectMembers([]);
          this.setAddedProjectMembers(false);
          this.$notify.success({
            type: 'success',
            message: 'Successfully updated.'
          });
        })
          .catch(() => {
            this.$notify.error({
              message: 'Error updating member. Please try again later.'
            });
          })
          .finally(() => {
            this.onInitialize();
          });
      }
    },
    onDeleteMember(projectMemberId) {
      this.memberDeleteId = projectMemberId;
      this.showConfirmDeleteModal = true;
    },

    deleteMember() {
      const { currentSelectedProject } = this;
      const { id: projectId } = currentSelectedProject;
      const projectMemberId = this.memberDeleteId;

      if (projectMemberId && projectId) {
        const payload = {
          projectId,
          projectMemberId,
        };
        this.deleteProjectMember(payload).then(async () => {
          this.$notify.success({
            type: 'success',
            message: 'Successfully deleted member.'
          });
        })
          .catch(() => {
            this.$notify.error({
              message: 'Error deleting member. Please try again later.'
            });
          })
          .finally(() => {
            this.onInitialize();
            this.memberDeleteId = null;
            this.showConfirmDeleteModal = false;
          });
      }
    },
    onCancel() {
      this.memberDeleteId = null;
      this.showConfirmDeleteModal = false;
    }
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

    .property-members-container * p {
        margin: 0;
    }
    .property-members-container * .el-card {
        border-radius: 16px;
    }
    .property-members-container  {
        // height:calc(100% - 8.3rem);
        // background:yellow;
        max-width: 1230px;
        gap: 2rem;
        padding-bottom: 5rem;
        position:relative;

        .no-content-container {
            // height: calc(100vh - 160px);
            // height: 100%;
            margin-top: 4rem;
        }

        &__header-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0.25px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            line-height: 1.2em;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        &__header {
            padding-bottom: 1rem;
            position: sticky;
            top: 0;
            z-index: 2;
            background: #F1F6FB;
        }

        .tile-view-members-container {
            display:grid;
            grid-template-columns: repeat(auto-fill, minmax(278px,1fr));
            justify-content:flex-start;
            gap: 35px;
            position:relative;
            padding: 0 .6rem;
            // top:6rem;
            // max-width: 1130px;
            // padding-bottom: 5rem;

            :deep(.el-card__body) {
                display:flex;
                flex-direction: column;
                gap: 2rem;

                .el-divider {
                    margin: 0;
                }
            }

            &__profile-details {
                display:flex;
                gap: 1rem;
                justify-content:flex-start;
                align-items:center;

                .member-name {
                  display:flex;
                  flex-direction: column;
                //   gap:.5rem;

                  .first-name {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 30px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                    color: #0C0F4A;
                  }
                  .last-name {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                    color: #0C0F4A;
                  }
                }
            }

            .member-email-container, .member-role-container {
                display:flex;
                flex-direction: column;
                gap:.5rem;

                .label {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    color: #0C0F4A;
                }
            }

            .member-email-container {
                .email {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                    color: #0C0F4A;
                }
            }

            .member-role-container {
                :deep(.el-input__inner) {
                    border: none;
                    outline: none;
                    background: transparent;
                    padding: 0;
                }
            }
        }
    }
@include media-sm-max-width() {
  .property-members-container {
    &__header-title {
      font-size: 18px;
    }
  }
}
@include media-xs-max-width() {
  .property-members-container {
    &__header-title {
      font-size: 14px;
    }
  }
}
</style>
